import React from 'react'
import MenuMobile from './MenuMobile'

const Footer = () => {
    return (
        <div className='footer'>
            <MenuMobile />

        </div>
    )
}

export default Footer