import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { faRectangleList } from '@fortawesome/free-solid-svg-icons'
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';

import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";



const MenuMobile = () => {

    const navigate = useNavigate();
    const { userInfo } = useSelector(state => state.user);
    const location = useLocation();


    return (
        <div className='menuMobile'>
            {userInfo ?
                <nav className='menuMobileContent'>
                    <FontAwesomeIcon icon={faHouse}
                        className={location.pathname === '/homePage' ? 'menuMobileIconActive' : ''}


                        onClick={() =>
                            navigate('/homePage')
                        }
                    />

                    <FontAwesomeIcon icon={faMagnifyingGlass}
                        className={location.pathname === '/searchMedia' ? 'menuMobileIconActive' : ''}
                        onClick={() =>
                            navigate('/searchMedia')}
                    />
                    <FontAwesomeIcon icon={faRectangleList}
                        className={location.pathname === '/listFilter' ? 'menuMobileIconActive' : ''}
                        onClick={() =>
                            navigate('/listFilter ')}
                    />
                    <FontAwesomeIcon icon={faUserGroup}
                        onClick={() =>
                            navigate('/friendsListPage')}
                    />
                </nav> : null}

        </div>
    )
}

export default MenuMobile