import { wait } from '@testing-library/user-event/dist/utils';
import React, { useLayoutEffect } from 'react'

const RandomMedia = ({ medias }) => {



    const mediasLocal = medias.filter((media) => media.mediaDetails.backdrop_path !== null && media.mediaDetails.overview !== "");

    const randomIndex = (medias) => {
        return Math.floor(Math.random() * mediasLocal.length)

    }

    let randomMedia = mediasLocal[randomIndex(medias)];
    useLayoutEffect(() => {
        wait(1000);
        return () => {
            wait(1000);
        }
    }, [randomMedia]
    );


    const selectType = () => {
        if (randomMedia.mediaDetails.type === "tv") {
            return (randomMedia.mediaDetails.name)
        } else {
            return (randomMedia.mediaDetails.title)
        }
    }

    return (
        <div className="lastMedia">
            <div className='lastMediaText' >

                <h3>{mediasLocal.length > 1 ? selectType() : ""}</h3>
                {/* .split(" ").slice(0, 30).join(" ") => permet de tronquer le texte sans couper un mot / ici, le slice restreint le texte au nombre de caractere grace au split et .join permet de remplacer les "," enjendrer par le split (il met des "," a la place des espaces) par des espaces */}
                <p>{mediasLocal.length > 1 ? randomMedia.mediaDetails.overview.split(" ").slice(0, 50).join(" ") + "..." : ""}</p>
            </div>
            <div className="filterImg"></div>
            <div className='lastMediaImg'>
                {/* {mediasLocal.length > 1 ? <img src={randomMediaUrl} alt="" /> : ""} */}
                {mediasLocal.length > 1 ? <img src={`https://image.tmdb.org/t/p/original${randomMedia.mediaDetails.backdrop_path}`} alt="" /> : ""}
                {/*  */}
            </div>

        </div>
    )
}

export default RandomMedia