import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../styles/ModalFriendList.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { deleteMedia, getMedias } from "../features/medias/mediasAction";
import { getUserDetails } from '../features/user/userActions';

import { useRef } from "react";


const ModalFriendList = ({ friendListTitle, nbMediaInCommon }) => {

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const toggleModal = () => {
    setShowModal(!showModal);
  };



  return (
    <>
      <p onClick={toggleModal} className="btn-modal-friend-medal">
        Media en commun : {nbMediaInCommon}
      </p>
      <div className="modalFullscrenne"></div>
      {/* lui donner une condition ! modal "true" alors apparition du madal */}
      {showModal && (
        <div className="">
          <div className="overlay-friend-media">
            <div className="modal-friend-media">
              <div className="modal-content-friend-mediad">

                <span className="list-media-friend"
                  style={
                    {
                      display: "flex",
                      flexDirection: "column",

                      overflow: "hidden",
                      overflowY: "scroll",
                      // flexWrap: "wrap",
                      padding: "0.5rem",
                      margin: "0.5rem",
                    }}
                > {friendListTitle}</span>
                <button onClick={toggleModal} className="close-modal-friend-media ">
                  <FontAwesomeIcon icon={faCircleXmark} />

                </button>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
}

export default ModalFriendList;
