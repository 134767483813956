import React, { Children } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FilteredMediaDisplay = ({ media }) => {
	// console.log(media);
	const fire = [];
	const navigate = useNavigate();
	const mediaID = media._id;

	for (let i = 0; i < media.formDetails.hype; i++) {
		// console.log('bouble for ' + i);
		let fireItem = <FontAwesomeIcon className="fontAwesomeIcon" icon={faFire} />;
		fire.push(fireItem);
		console.log(fire);
	}

	return (
		<motion.div
			animate={{ opacity: 1 }}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.5 }}
			layout
			className="mediaFlexContainer"
			onClick={() => {
				console.log(media);
				navigate('/MediaDetails', { state: media });
			}}
		>
			<img src={`https://image.tmdb.org/t/p/w500${media.mediaDetails.poster_path}`} alt="" />
			<div className="mediaInfo">
				<span className="hypeInfo">{fire}</span>
				<span>{media.mediaDetails.type == 'movie' ? media.mediaDetails.title : media.mediaDetails.name}</span>
			</div>
		</motion.div>
	);
};

export default FilteredMediaDisplay;
