import React from 'react'
import { Link } from 'react-router-dom'

const MenuDesktop = () => {
    return (
        <div className='menuDesktop'>
            <ul>
                <li>
                    <Link to={"/homePage"}> Accueil </Link>
                </li>
                <li>
                    <Link to={"/searchMedia"}> Ajouter un média </Link>
                </li>
                <li>
                    <Link to={"/listFilter"}> Mes listes </Link>
                </li>
                <li>
                    <Link to={"/friendsListPage"} >
                        Amis
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default MenuDesktop