import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getUserDetails, uptdateProfil, uptdatePassword } from "../features/user/userActions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useRef } from "react";
import { useEffect } from "react";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { logout } from '../features/user/userSlice';
import { useNavigate } from "react-router-dom";
import { deleteUser } from "../features/user/userActions";

import "../styles/modalProfil.scss";



const ModalProfile = ({ user, setRemoveEventListener, modalRef, showModal, setShowModal }) => {

  // const modalRef = useRef();

  const navigate = useNavigate()
  const [matchPwd, setMatchPwd] = useState('');

  const ref = useRef(null)
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;


  // const [showModal, setShowModal] = useState(false);
  const [showEditPseudo, setShowEditPseudo] = useState(false);
  const [showEditMail, setShowEditMail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [erroMEssage, setErrorMessage] = useState('');
  const [validPassword, setValidPwd] = useState();


  const [uptdateProfile, setUptdateProfile] = useState(
    {}
  )
  const [uptdatePasswordInput, setUptdatePasswordInput] = useState(
    {}
  )

  useEffect(
    () => {
      setValidPwd(PWD_REGEX.test(uptdatePasswordInput.newPassword));

      // console.log(validPassword)
    },
    //Cette fois on surveille les changements du champ mdp mais aussi du champ de confirmation de celui ci, si un des deux changes alors le useEffect est relancé
    [uptdatePasswordInput.newPassword]
  );

  useEffect(() => {
    setUptdateProfile({
      pseudo: user.pseudo,
      email: user.email
    })
  }, [user])

  const dispatch = useDispatch();
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleInputPseudo = () => {
    setShowEditMail(false)
    setShowEditPseudo(!showEditPseudo)

  }
  const toggleInputMail = () => {
    setShowEditPseudo(false)

    setShowEditMail(!showEditMail)
  }

  useEffect(() => {
    if (showEditPseudo || showEditMail) {
      ref.current.focus()
    }
  }
    , [showEditMail, showEditPseudo])



  return (
    <>
      <button onClick={toggleModal} className="btn-modal-profil">
        <FontAwesomeIcon icon={faUser} />
      </button>
      {/* <div className="modalFullscrenne"></div> */}
      {/* lui donner une condition ! modal "true" alors apparition du madal */}
      {showModal && (
        <div className="modalProfil">
          {/* <div className="overlay"> */}
          <div className="modal-profil"
            ref={modalRef}
          >
            <h3>Mon profil</h3>
            <div className="editProfilBtn">
              <div>

                {showEditPseudo ?

                  <form className=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      dispatch(uptdateProfil(uptdateProfile));
                      // console.log("uptdateProfile" + uptdateProfile);
                      setShowEditPseudo(!showEditPseudo);
                      dispatch(getUserDetails());
                    }}

                  >
                    <input type="text" name="editPseudo" ref={ref}
                      onChange={(e) => {
                        setUptdateProfile({ ...uptdateProfile, pseudo: e.target.value })
                      }}
                      style={
                        { width: `${user.pseudo.length + 1}ch` }}
                      defaultValue={user.pseudo}
                    />
                    <FontAwesomeIcon icon={faPenToSquare} className="editBtnToogle"
                      onClick={() => toggleInputPseudo("pseudo")}
                    />
                  </form>
                  : <div className="flexForm">
                    <span className="userName">{user.pseudo}</span>
                    <FontAwesomeIcon icon={faPenToSquare} className="editBtnToogle"
                      onClick={() => toggleInputPseudo("pseudo")}
                    />
                  </div>

                }
              </div>

            </div>



            {showEditMail ?
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  dispatch(uptdateProfil(uptdateProfile));
                  setShowEditMail(!showEditMail);
                  dispatch(getUserDetails());

                }}
              >
                <input type="text" name="editPseudo" ref={ref} style={
                  { width: `${user.email.length}ch` }}
                  onChange={(e) => {
                    setUptdateProfile({ ...uptdateProfile, email: e.target.value })

                  }}
                  defaultValue={user.email}
                />
              </form>

              : <div className="flexForm">
                <span>{user.email}</span>
                <FontAwesomeIcon icon={faPenToSquare} className="editBtnToogle"
                  onClick={() => toggleInputMail("email")}

                />
              </div>


            }
            <div
              className="pointer"
              style={{ fontSize: "2rem" }}
              onClick={
                () => {
                  navigate("/friendsListPage")
                  toggleModal()
                }}
            >
              {user.friends.length} ami{user.friends.length > 1 && "s"}
            </div>

            <form className={showPassword ? "" : "none"} onSubmit={
              (e) => {
                e.preventDefault();
                if (validPassword) {
                  dispatch(uptdatePassword(uptdatePasswordInput)).then(
                    (res) => {
                      if (res.payload.success) {
                        setErrorMessage('')
                        setUptdatePasswordInput({})
                        setShowModal(false)
                      } else {
                        setErrorMessage(res.payload.message)
                      }
                    }
                  )
                  setUptdatePasswordInput({});
                }

              }
            }>
              <input type="password" name="editPseudo" placeholder="Ancien password"
                onChange={(e) => {
                  setUptdatePasswordInput({ ...uptdatePasswordInput, oldPassword: e.target.value })
                }}
              />
              <input type="password" name="editPseudo" placeholder="nouveau password"
                onChange={(e) => {
                  setUptdatePasswordInput({ ...uptdatePasswordInput, newPassword: e.target.value })
                }
                }
              />
              <span className="error" style={
                {
                  display: validPassword ? "none" : "block",
                  fontSize: "1rem"
                }
              }>{
                  validPassword ? "" : "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"

                }</span>
              <span>
                {erroMEssage}
              </span>
              <button
                type="submit"
              >Changer de mot de passe</button>

            </form>
            <button className="btnStyle btnSmall"
              onClick={() => {
                // toogle display none sur le formulaire de modification de mdp
                setShowPassword(!showPassword)
              }}

            >Modifier mon mot de passe</button>




            <br />

            <button className="btnStyle"
              onClick={() => {
                dispatch(logout())
                navigate("/")
                setShowModal(false)
              }}> Se deconnecter </button>
            <button onClick={
              () => {
                toggleModal();
              }
            } className="close-modal">
              <FontAwesomeIcon icon={faCircleXmark} />

            </button>

            <h4>Attention !!!!! <br />Cliquer sur le bouton ci-dessous supprimera définitivement votre compte et son contenu !</h4>
            <button className="btnStyle"
              onClick={() => {
                dispatch(deleteUser());
              }}>
              supprimer mon compte
            </button>
            {/* </div> */}
          </div>
        </div>
      )
      }
    </>
  );
}

export default ModalProfile;