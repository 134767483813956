import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const API_URL = process.env.REACT_APP_API_URL;

export const getFriends = createAsyncThunk('friends/getFriends', async (arg, { getState }) => {
	const { user } = getState();
	try {
		// console.log('getFriends user id' + user.userInfo._id);

		const { data } = await axios.post(`${API_URL}getFriends`, { id: user.userInfo._id });
		return data;
	} catch (error) {
		console.log(error);
	}
});

export const addFriend = createAsyncThunk('user/addFriends', async (friendPseudo, { getState }) => {
	// console.log('freiendPseudo', friendPseudo);
	try {
		const { user } = getState();
		const userID = user.userInfo._id;
		const config = {
			headers: {
				Authorization: `Bearer ${user.userToken}`
			}
		};
		const { data } = await axios.post(`${API_URL}addFriend`, { friendPseudo, userID }, config);

		return data;
	} catch (error) {
		console.log(error);
	}
});
