import { createSlice } from "@reduxjs/toolkit";
import { getMedias, deleteMedia } from "./mediasAction";


const initialState = {
    loading: false,
    userInfo: false,
    medias: [],
    error: null,
    success: false,
}


const mediasSlice = createSlice({
    name: 'medias',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getMedias.pending]: (state, action) => {
            state.success = false;
            state.loading = true;
        },
        [getMedias.fulfilled]: (state, action) => {
            console.log("payload : " + action.payload);
            state.loading = false;
            state.medias = action.payload.media
            state.suggestions = action.payload.suggestions;
            state.success = true;
        },
        [getMedias.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [deleteMedia.pending]: (state, action) => {
            state.loading = true;
        },
        [deleteMedia.fulfilled]: (state, action) => {
            state.loading = false;
            // state.medias = action.payload;
            state.success = true;
        },
        [deleteMedia.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }

    }
})

export default mediasSlice.reducer;