import React, { useState } from "react";
import "../styles/modalAddFriend.scss";
import { useDispatch, useSelector } from "react-redux";
import { addFriend } from "../features/friends/friendsAction";
import { getFriends } from "../features/friends/friendsAction";

export default function ModalAddFriend({ textBtnOpenModal, textBtnCloseModal, textTitleModal, textContentModal, textBtnSubmit, listFriendsLocal, setListFriendsLocal }) {

  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);
  //on cree un state sur false


  const toggleModal = () => {
    setShowModal(!showModal);
  };
  //On lui cree une fonction toggle qu'on relira au bouton pour l'apparition du Modal

  const [ami, setAmi] = useState({});

  const dispatch = useDispatch();
  const [messageSubmitLocal, setMessageSubmitLocal] = useState("");

  const messageSubmit = useSelector((state) => state.friends.addingMessage);




  return (
    <>
      <button onClick={toggleModal} type='button' className="btn-modal-friend">
        {textBtnOpenModal}
      </button>
      {/* lui donner une condition ! modal "true" alors apparition du madal */}
      {showModal && (
        <div className="overlay">
          <div className="modal">
            <div className="modal-content">
              <div className="client">

                <div className="form-group">

                  <label htmlFor="">Pseudo de votre ami</label>
                  <input type="text" className="form-control" id="nom"
                    onBlur={(e) => {
                      setAmi(e.target.value)
                    }
                    }
                  />


                </div>


                <button className="btnStyle"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("amieeee", ami);
                    // quand la réponse du dispact est succes on met à jour le message subtmit local
                    dispatch(addFriend(ami)).then((response) => {

                      setMessageSubmitLocal(response.payload);
                      if (response.payload === "success") {

                        dispatch(getFriends());
                        setListFriendsLocal(
                          [...listFriendsLocal, ami]
                        )
                      }

                    }

                      // setMessageSubmitLocal(messageSubmit);
                      // dispatch(addFriend(ami));
                    )
                    // // toggleModal();
                  }
                  }

                > {textBtnSubmit}</button>

                <span className="messageAlert">{messageSubmitLocal}</span>

                <button onClick={
                  () => {
                    dispatch(getFriends())
                    setMessageSubmitLocal("")
                    toggleModal();

                  }
                } className="close-modal">
                  X
                </button>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
}
