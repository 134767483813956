import React, { useEffect } from 'react'
import FriendItem from '../components/FriendItem'
import { useSelector } from 'react-redux'
import ModalAddFriend from '../components/ModalAddFriend'
import { useState } from 'react'

const FriendsListPage = () => {

    // const friends = useSelector((state) => state.friends.friends)
    const listFriends = useSelector((state) => state.friends.friends)
    const [listFriendsLocal, setListFriendsLocal] = useState()

    let friendElements


    useEffect(() => {
        setListFriendsLocal(listFriends)
    }, [listFriends])

    if (listFriendsLocal) {
        friendElements = listFriendsLocal.map((friend) => {
            // console.log(friend);
            return (

                <FriendItem friend={friend} />
            )
        })
    }


    return (
        <div>
            <div className='friendList marginButton'>
                <h1 >Mes amis</h1>
                <div className='containerBtnModal'>

                    <ModalAddFriend
                        textBtnOpenModal="Ton ami n'est pas dans la liste ? Ajoute le à ta liste d'ami !"
                        textBtnCloseModal="Fermer Modal"
                        textTitleModal="Titre Modal"
                        textContentModal="Contenu Modal"
                        textBtnSubmit="Envoyer"
                        // passer le state et le setState de listFriendsLocal
                        listFriendsLocal={listFriendsLocal}
                        setListFriendsLocal={setListFriendsLocal}
                    />
                </div>

                {friendElements}
            </div>
        </div>
    )
}

export default FriendsListPage