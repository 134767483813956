import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import ModalDelete from './ModalDelete'
import { getMediasFriend } from '../features/medias/mediasAction';
import ModalFriendList from './ModalFriendList';

const FriendItem = ({ friend }) => {

    const dispatch = useDispatch()

    // verifier le nombre de media en commun entre l'user et le friend
    const user = useSelector((state) => state.user.userInfo)
    const userMedia = user.media
    const friendMedia = friend.medias
    const [mediaCommunTitle, setMediaCommunTitle] = React.useState([])

    useEffect(() => {
        const userID = friend._id
        const result = dispatch(getMediasFriend(userID)).then((res) => {
            console.log(res.payload);
            // filtrer le résultat pour obtenir uniquement les medias en commun
            const medias = res.payload.filter((media) => {
                return userMedia.includes(media._id)
            })
            setMediaCommunTitle(medias?.map((media) => {
                return (
                    <div className="">
                        <h1
                            style={
                                {
                                    fontSize: "0.8rem",
                                    padding: "0rem",
                                    margin: "0rem",
                                    fontfamily: "arial",

                                }}
                        > {media.title ? media.title : media.name} </h1>
                    </div >
                )
            }))
        })

    }, [])

    // medias?.map((media) => {
    //     return (
    //         <div className="media">
    //             <h1> {media.title} </h1>
    //         </div>
    //     )
    // })

    const mediaInCommon = userMedia.filter(media => friendMedia.includes(media))
    const nbMediaInCommon = mediaInCommon.length


    return (
        <div className='friendItem'>
            <span>
                <FontAwesomeIcon icon={faUser} />
            </span>
            <div className="textFriendItem" >
                <h2>{friend.pseudo}</h2>
                {/* <p> Mediax en commun  : {nbMediaInCommon}</p> */}
                {/* <p>{mediaCommunTitle ? mediaCommunTitle : "test"}</p> */}
                {/* ///affichage au hover d'une petite modal pour afficher les titres */}

                <ModalFriendList
                    friendListTitle={mediaCommunTitle}
                    nbMediaInCommon={nbMediaInCommon}
                />




            </div>


        </div>
    )
}

export default FriendItem