import { useSpringCarousel } from 'react-spring-carousel'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CarouselItem from './CarouselItem';

// L code du carrousel / il recupère en props les informations de l'api dans la page ""

const Carousel = (props) => {
    // recuperation du params du composant


    const tab = props.item

    const nbItem = tab.length
    const action = props.action



    const { carouselFragment, slideToPrevItem,
        slideToNextItem } = useSpringCarousel({
            itemsPerSlide: nbItem > 10 ? 10 : nbItem,
            withLoop: nbItem > 6 ? true : false,
            // withLoop: true,
            // espace entre les images
            gutter: 5,
            // décalage sur la gauche
            startEndGutter: 0,
            // slideType: 'fluid',
            items: tab.map((item, index) =>
            ({
                id: item.id,
                renderItem: (
                    <CarouselItem item={item}
                        action={action}
                    />

                )
            })
            )
        })

    return (
        <div className={window.innerWidth > 768 && nbItem < 4 ? "smallCarousel carousel" : "carousel"}

        >

            <FontAwesomeIcon id='btn-prev' className='arrow' onClick={slideToPrevItem} icon={faChevronLeft} />
            {carouselFragment}
            <FontAwesomeIcon id='btn-next' className='arrow' onClick={slideToNextItem} icon={faChevronRight} />
        </div >
    )
}

export default Carousel
