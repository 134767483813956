import { createSlice } from "@reduxjs/toolkit";
import { getFriends } from "./friendsAction";
import { addFriend } from "./friendsAction";


const initialState = {
    loading: false,
    friends: [],
    addingMessage: "",
    error: null,
    success: false,
}


const friendsSlice = createSlice({
    name: 'friends',
    initialState,
    reducers: {

    },
    extraReducers: {
        [getFriends.pending]: (state, action) => {
            state.loading = true;
        },
        [getFriends.fulfilled]: (state, action) => {
            state.loading = false;
            state.friends = action.payload;
            state.success = true;
        },
        [getFriends.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        [addFriend.pending]: (state, action) => {
            state.loading = true;
        },
        [addFriend.fulfilled]: (state, action) => {
            state.loading = false;
            state.addingMessage = action.payload;
            state.success = true;
        },
        [addFriend.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }


    }
});

export default friendsSlice.reducer;