import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from './root';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux';
import './styles/main.scss';
import store from './app/store';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Root />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
