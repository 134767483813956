import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";




/**
 * @description ProtectedRoute qui permet de protéger les routes . toutes routes protégées doivent être enfant de ProtectedRoute. Si l'utilisateur n'est pas connecté, il est redirigé vers la page de connexion. 
 * 
 
 */
const ProtectedRoute = () => {
    const { userInfo } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const navigateFonction = () => {
        navigate('/login');
    }

    useEffect(
        () => {
            if (userInfo == false) {
                navigate('/login');
            }
        }, []
    )
    if (userInfo === false || userInfo === undefined) {
        setTimeout(() => {
            navigate('/login')
        },)
        return null;
    }
    else {
        //     console.log('user connected');
        return <Outlet />
    }
}

export default ProtectedRoute;