import React from 'react';
import { useNavigate } from 'react-router-dom';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../features/user/userActions';

import CGU_PDF from '../docs/HyppyList-CGU.pdf';

const Register = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { userMsg, success } = useSelector((state) => state.user);

	// Initialisation des REGEX qui vont nous permettre de décider des règles de format du pseudo et du MDP de l'user
	const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
	const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
	const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

	// Le hook UseRef va nous permettre d'accèder à un élèment du dom (comme document.querySelector en Js) pour que cela fonctionne il faut attribuer la userRef à un attribut ref de l'élèment auquel on souhaite avoir accès
	// Le but ici étant de mettre le focus par défault sur le premier champ du formulaire / de controler quel champ à le focus
	const userRef = useRef();
	// const errRef = useRef();

	// Mise en place du state qui va influer sur la valeur du champ Username
	const [userName, setUser] = useState('');
	// Ce state va contrôler la validité de notre Username Selon les règle défini dans le REGEX (voir le useEffect plus bas). Il contient un booléen
	const [validUserName, setValidUserName] = useState(false);
	//Ce state nous permet de savoir si l'input username à le focus, il contient un booléen
	const [userNameFocus, setUserNameFocus] = useState(false);

	// La même chose qu'au dessus mais pour le MDP
	const [password, setPassword] = useState('');
	const [validPassword, setValidPwd] = useState(false);
	const [passwordFocus, setPasswordFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);
	// La même chose avec l'adresse E-mail
	const [email, setEmail] = useState('');
	const [validEmail, setValidEmail] = useState(false);
	const [emailFocus, setEmailFocus] = useState(false);

	// const [errMsg, setErrMsg] = useState('');

	const [validCGU, setValidCGU] = useState(false);
	console.log(validCGU);

	//Ce useEffect, après le premier render de la page nous permet d'attribuer le focus au champ Username grâce au hook UseRef associé à la const UserRef plus tôt et à l'attribut ref de l'input username
	useEffect(() => {
		userRef.current.focus();
	}, []);

	// Après le premier render de la page on met dans set valid name le résultat du test (défini par le REGEX) la chaine contenue dans le state user
	useEffect(
		() => {
			setValidUserName(USER_REGEX.test(userName));
		},
		//Dès que le mdp chanche on refait l'opération du dessus, on retest
		[userName]
	);

	// Pareil avec l'email
	useEffect(
		() => {
			setValidEmail(EMAIL_REGEX.test(email));
		},
		//Dès que l'email change on refait l'opération du dessus, on retest sa validité
		[email]
	);

	//Pareil que précèdement mais pour le mdp, on compare également la chaine contenue dans le password avec celle contenue dans la confirmation du password
	useEffect(
		() => {
			setValidPwd(PWD_REGEX.test(password));
			setValidMatch(password === matchPwd);
		},
		//Cette fois on surveille les changements du champ mdp mais aussi du champ de confirmation de celui ci, si un des deux changes alors le useEffect est relancé
		[password, matchPwd]
	);

	// const verifCGU = (e) => {
	// 	console.log(e.target.checked);
	// 	if (e.target.checked === 'checked') {
	// 		setValidCGU(true);
	// 	} else {
	// 		setValidCGU(false);
	// 	}
	// }
	useEffect(
		() => {
			if (success && !userMsg) {
				navigate(`/login`);
			}
		},
		[success, userMsg]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// Prévention au cas ou qq arriverait à activer notre boutons sans remplir les champs
		const v1 = USER_REGEX.test(userName);
		const v2 = PWD_REGEX.test(password);
		const v3 = EMAIL_REGEX.test(email);
		if (!v1 || !v2 || !v3) {
			userMsg = ('Entrée Invalide veuillez recommencer');
			return userMsg;
		} else {
			const data = {
				pseudo: userName.toLowerCase(),
				email: email,
				password: password
			};
			dispatch(register(data));
		}
	};
	return (
		<section className="flexFormContainer registerPadding ">
			<form onSubmit={handleSubmit}>
				{/* affichage conditionnel d'un tic de validation ou d'une ptite croix
                Si le state validPseudo renvoie vrai alors l'icone de validation prends la classe "valid" ce qui affiche l'icone, sinon elle est caché 
                 */}
				<label htmlFor="pseudo">
					Pseudo
					<FontAwesomeIcon icon={faCheck} className={validUserName ? 'valid' : 'hide'} />
					{/* Même raisonnement pour la croix sauf qu'on test si validPseudo renvoi vrai et si user renvoie faux (est vide) avant d'attribuer les classes hide : invalid */}
					<FontAwesomeIcon icon={faTimes} className={validUserName || !userName ? 'hide' : 'invalid'} />
				</label>
				{/* <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">
					{errMsg}
				</p> */}

				{/* Les attributs aria-invalid et aria describedby sont la pour l'accesibilité */}
				<input
					type="text"
					id="pseudo"
					ref={userRef}
					autoComplete="off"
					onChange={(e) => setUser(e.target.value)}
					value={userName}
					required
					aria-invalid={validUserName ? 'false' : 'true'}
					aria-describedby="uidnote"
					onFocus={() => setUserNameFocus(true)}
					onBlur={() => setUserNameFocus(false)}
				/>


				{/* L'on vérifie ici le champ username prends le focus(que le state userFocus renvoi vrai), qu'il ne soit pas vide (le state user n'est pas vide) et que le state validPseudo renvoie faux avant d'apppliquer la classe "instruction" à notre p ce qui va le rendre visible sinon on applique "offscreen" qui via un positionnement absolue situe le p en dhors de l'écran
                Nous utilisons cette méthodes afin que les screens reader ai quand même accès au message dans ce cas la*/}

				<p id="uidnote" className={userNameFocus && userName && !validUserName ? 'istructions' : 'offscreen'}>
					<FontAwesomeIcon icon={faInfoCircle} /> 4 à 24 charactere.<br />
					Le pseudo doit commencer par par une lettre.<br />
					Lettres, chiffres, trait d'union accéptés
				</p>

				<label htmlFor="email">
					Email
					<FontAwesomeIcon icon={faCheck} className={validEmail ? 'valid' : 'hide'} />
					<FontAwesomeIcon icon={faTimes} className={validEmail || !email ? 'hide' : 'invalid'} />
				</label>

				<input
					type="text"
					id="email"
					ref={userRef}
					autoComplete="off"
					onChange={(e) => setEmail(e.target.value)}
					value={email}
					required
					aria-invalid={validUserName ? 'false' : 'true'}
					aria-describedby="uidnote"
					onFocus={() => setEmailFocus(true)}
					onBlur={() => setEmailFocus(false)}
				/>

				{/* <p id="uidnote" className={emailFocus && email && !validEmail ? 'istructions' : 'offscreen'}>
					<FontAwesomeIcon icon={faInfoCircle} /> Veuillez entrer une adresse E-mail valide<br />
					
				</p> */}

				{/* les même opération d'affichage conditionnelle des icones de tick et de croix sont répétées ici avec le mot de passe */}
				<label htmlFor="password">
					Mot de passe
					<FontAwesomeIcon icon={faCheck} className={validPassword ? 'valid' : 'hide'} />
					<FontAwesomeIcon icon={faTimes} className={validPassword || !password ? 'hide' : 'invalid'} />
				</label>
				<input
					type="password"
					id="password"
					onChange={(e) => setPassword(e.target.value)}
					value={password}
					required
					aria-invalid={validPassword ? 'false' : 'true'}
					aria-describedby="passwordnote"
					onFocus={() => setPasswordFocus(true)}
					onBlur={() => setPasswordFocus(false)}
				/>

				{/* La même logique d'affichage conditionnel que pour l'username est appliqué à ce p exepté qu'on ne prends pas en compte le fait que le state password soit vide ou non, si le champ à bien le focus (passwordFocus = true) et tant qu'il ne contiens pas un mot de passe valide (!validPassword = true) alors on affiche les instructions sinon on les situe en dehors de l'écran */}
				<p id="passwordnote" className={passwordFocus && !validPassword ? 'instructions' : 'offscreen'}>
					<FontAwesomeIcon icon={faInfoCircle} />
					8 à 24 caractère.<br />
					Doit inclure au moins une lettre majuscule, un chiffre et un caractère spécial<br />
					caractère spéciaux autorisés:
					{/* Les caractères spéciaux sont mis dans des spans afin de pouvoir leur attribuer "aria-label"  qui va permettre qu'il soit lu par les screen reader */}
					<span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span>{' '}
					<span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span>{' '}
					<span aria-label="percent">%</span>
				</p>

				<label htmlFor="passwordConfirmation">
					Confirmer le Mdp
					<FontAwesomeIcon icon={faCheck} className={matchPwd && validMatch ? 'valid' : 'hide'} />
					<FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? 'hide' : 'invalid'} />
				</label>
				<input
					type="password"
					id="passwordConfirmation"
					onChange={(e) => setMatchPwd(e.target.value)}
					value={matchPwd}
					required
					aria-invalid={validMatch ? 'false' : 'true'}
					aria-describedby="confirmnote"
					onFocus={() => setMatchFocus(true)}
					onBlur={() => setMatchFocus(false)}
				/>
				<p id="confirmnote" className={matchFocus && !validMatch ? 'instructions' : 'offscreen'}>
					<FontAwesomeIcon icon={faInfoCircle} />
					Doit être identique au mot de passe.
				</p>
				<div className="CGU-Section">
					<input id="CGU" type="checkbox" onClick={() => setValidCGU(!validCGU)} />
					<label htmlFor="CGU">Cliquez pour accepter les conditions générales d'utilisation</label>
				</div>
				{/* rel="noreferrer" retire le warning de sécurité du target="_blank" */}
				<h2>
					<a href={CGU_PDF} target="_blank" rel="noreferrer">
						Lire les conditions générales d'utilisation
					</a>
				</h2>
				<button disabled={!validUserName || !validPassword || !validMatch || !validCGU ? true : false}>
					S'enregistrer
				</button>
			</form>
			<p>{userMsg}</p>
		</section>
	);
};

export default Register;
