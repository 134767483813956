import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const API_URL = process.env.REACT_APP_API_URL;

export const getAllUsers = createAsyncThunk('user/getAllUsers', async () => {
	try {
		const usersList = await axios.get(`${API_URL}users`);
		// console.log(usersList.data);
		return usersList.data;
	} catch (error) {
		console.log(error);
	}
});
/**
 * envoie les infos de login au serveur et récupère le token en réponse si les informations correspondent à un utilisateur
 * @param {string} email
 * @param {string} password
 * @returns {object} user
 * @returns {string} user.token
 * 
 */

// Ici on sait qu'on cherche l'email est le password car dans login.jsx on fait appel à cette action dans notre dispatch et on lui passe data (que l'on récupére depuis useForm)
// la fonction va ensuite chercher email et password dans data

export const userLogin = createAsyncThunk('user/userLogin', async ({ email, password }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*'
			},
			// recupere les cookies
			withCredentials: true,
			Credentials: 'include'
		};
		// console.log(email, password);
		const data = await axios.post(`${API_URL}users/login`, { email, password }, config);

		if (data.data.token != undefined) {
			localStorage.setItem('userToken', data.data.token);
		}

		return data.data;
	} catch (error) {
		console.log(error.response.data);
		return error.response.data.message;
	}
});

/**
 * récupère les infos de l'utilisateur connecté via son token enregistré dans le localStorage du navigateur  
 * @returns {object} user
 * @returns {string} user.email
 * @returns {string} user.name
 * @returns {string} user._id 
 */

export const getUserDetails = createAsyncThunk(
	'user/getUserDetails',
	// On utilise cette syntaxe car ce qu'on donne a manger a notre AsyncThunk ne sont pas des paramètres auquel on aurait accès via dispatch
	// Mais des arguments

	async (arg, { getState, rejectWithValue }) => {
		try {
			const { user } = getState();

			const config = {
				headers: {
					Authorization: `Bearer ${user.userToken}`
				}
			};
			const { data } = await axios.get(`${API_URL}users/profile`, config);
			// localStorage.setItem('userInfos', JSON.stringify(data));

			return data;
		} catch (error) {
			console.log(error);
			return rejectWithValue(error.response.data.message);
		}
	}
);

export const addMedia = createAsyncThunk('user/addMedia', async (datas, { getState }) => {
	try {
		const { user } = getState();
		// const userId = user.userInfos._id;
		const userID = user.userInfo._id;
		const config = {
			headers: {
				Authorization: `Bearer ${user.userToken}`
			}
		};
		const reponse = await axios.post(`${API_URL}addMedia`, { datas, userID }, config);
	} catch (error) {
		console.log(error);
	}
});

export const register = createAsyncThunk('auth/register', async ({ pseudo, email, password }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			}
		};
		const response = await axios.post(
			API_URL + 'users/register',
			{
				pseudo,
				email,
				password
			},
			config
		);
		return response;
	} catch (error) {
		console.log(error.respone.data);
		// return rejectWithValue(error.response.data.message);
	}
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (arg, { getState }) => {
	try {
		const { user } = getState();
		const userID = user.userInfo._id;
		const reponseDelete = await axios.post(`${API_URL}users/deleteUser`, { userID });
		console.log(reponseDelete);
	} catch (error) {
		console.log('erreur');
	}
});
export const uptdateProfil = createAsyncThunk('users/uptdateProfile', async (uptdateProfile, { getState }) => {
	try {
		// console.log('data' + uptdateProfile.pseudo);
		const pseudo = uptdateProfil.pseudo;
		const { user } = getState();
		const userID = user.userInfo._id;
		const config = {
			headers: {
				Authorization: `Bearer ${user.userToken}`
			}
		};
		const reponse = await axios.post(`${API_URL}updateProfile`, { uptdateProfile, userID }, config);
		// console.log(reponse);
	} catch (error) {
		console.log(error);
	}
});

export const uptdatePassword = createAsyncThunk('users/uptdatePassword', async (uptdatePassword, { getState }) => {

	try {
		const { user } = getState();
		const userID = user.userInfo._id;
		const config = {
			headers: {
				Authorization: `Bearer ${user.userToken}`
			}
		};
		const reponse = await axios.post(`${API_URL}updatePassword`, { uptdatePassword, userID }, config);
		console.log(reponse);
	} catch (error) {
		console.log(error);
	}
});

export const forgottenPwd = createAsyncThunk('/forgottenPassword', async ({ email }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				//cors
				'Access-Control-Allow-Origin': 'http://localhost:3000'
			}
		};
		const response = await axios.post(`${API_URL}forgottenPassword`, { email }, config);
		// console.log(response);
		return response;
	} catch (error) {
		console.log(error.response.data);
	}
});

export const resetPwd = createAsyncThunk('/resetPassword', async ({ password, id, token, matchPwd }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',

				'Access-Control-Allow-Origin': 'http://localhost:3000'
			}
		};
		const response = await axios.post(`${API_URL}resetPassword/${id}/${token}`, { password, matchPwd }, config);
	} catch (error) {
		console.log(error.response.data);
	}
});
