import React from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { faCheck, faTimes, faInfoCircle, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPwd } from '../features/user/userActions';

const ResetPassword = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { loading, success } = useSelector((state) => state.user);

	const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

	const [ password, setPassword ] = useState('');
	const [ validPassword, setValidPwd ] = useState(false);
	const [ passwordFocus, setPasswordFocus ] = useState(false);

	const [ matchPwd, setMatchPwd ] = useState('');
	const [ validMatch, setValidMatch ] = useState(false);
	const [ matchFocus, setMatchFocus ] = useState(false);
	const [ errMsg, setErrMsg ] = useState('');

	const [ showPwd, setShowPwd ] = useState(false);
	const [ showMatchingPwd, setShowMatchingPwd ] = useState(false);

	const userRef = useRef();

	const { id, token } = useParams();

	useEffect(
		() => {
			setValidPwd(PWD_REGEX.test(password));
			setValidMatch(password === matchPwd);
		},
		//Dès que l'email change on refait l'opération du dessus, on retest sa validité
		[ password, matchPwd ]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// Verif au cas ou le bouton serait activé par un truand

		const v2 = PWD_REGEX.test(password);
		const v3 = password === matchPwd;

		if (!v2 || !v3) {
			setErrMsg('Information Invalides');
			return;
		} else {
			const data = {
				password: password,
				id: id,
				token: token,
				matchPwd: matchPwd
			};
			dispatch(resetPwd(data));
			console.log(success);
		}
	};

	return (
		<div className="resetContainer">
			<div className="flexFormContainer">
				<h1>Réinitialiser votre mot de passe</h1>

				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<label htmlFor="password">
							Mot de passe
							<FontAwesomeIcon icon={faCheck} className={validPassword ? 'valid' : 'hide'} />
							<FontAwesomeIcon
								icon={faTimes}
								className={validPassword || !password ? 'hide' : 'invalid'}
							/>
						</label>
						<input
							type={showPwd ? 'text' : 'password'}
							id="password"
							onChange={(e) => setPassword(e.target.value)}
							value={password}
							required
							aria-invalid={validPassword ? 'false' : 'true'}
							aria-describedby="passwordnote"
							onFocus={() => setPasswordFocus(true)}
							onBlur={() => setPasswordFocus(false)}
						/>
						<FontAwesomeIcon
							icon={showPwd ? faEye : faEyeSlash}
							onClick={() => setShowPwd(!showPwd)}
							id="togglePassword"
						/>
					</div>

					{/* La même logique d'affichage conditionnel que pour l'username est appliqué à ce p exepté qu'on ne prends pas en compte le fait que le state password soit vide ou non, si le champ à bien le focus (passwordFocus = true) et tant qu'il ne contiens pas un mot de passe valide (!validPassword = true) alors on affiche les instructions sinon on les situe en dehors de l'écran */}
					<p id="passwordnote" className={passwordFocus && !validPassword ? 'instructions' : 'offscreen'}>
						<FontAwesomeIcon icon={faInfoCircle} />
						8 à 24 caractère.<br />
						Doit inclure au moins une lettre majuscule, un chiffre et un caractère spécial<br />
						caractère spéciaux autorisés:
						{/* Les caractères spéciaux sont mis dans des spans afin de pouvoir leur attribuer "aria-label"  qui va permettre qu'il soit lu par les screen reader */}
						<span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span>{' '}
						<span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span>{' '}
						<span aria-label="percent">%</span>
					</p>

					<div className="form-group">
						<label htmlFor="passwordConfirmation">
							Confirmer le Mdp
							<FontAwesomeIcon icon={faCheck} className={matchPwd && validMatch ? 'valid' : 'hide'} />
							<FontAwesomeIcon icon={faTimes} className={validMatch || !matchPwd ? 'hide' : 'invalid'} />
						</label>
						<input
							type={showMatchingPwd ? 'text' : 'password'}
							id="passwordConfirmation"
							onChange={(e) => setMatchPwd(e.target.value)}
							value={matchPwd}
							required
							aria-invalid={validMatch ? 'false' : 'true'}
							aria-describedby="confirmnote"
							onFocus={() => setMatchFocus(true)}
							onBlur={() => setMatchFocus(false)}
						/>
						<FontAwesomeIcon
							icon={showMatchingPwd ? faEye : faEyeSlash}
							onClick={() => setShowMatchingPwd(!showMatchingPwd)}
							id="togglePassword"
						/>
					</div>
					<p id="confirmnote" className={matchFocus && !validMatch ? 'instructions' : 'offscreen'}>
						<FontAwesomeIcon icon={faInfoCircle} />
						Doit être identique au mot de passe.
					</p>
					<button type="submit" disabled={!validPassword || !validMatch ? true : false}>
						Enregistrer
					</button>
				</form>

				{success ? <p> Votre mot de passe à bien été réinitialisé</p> : ''}
				{success ? <Link to="/login">Se connecter</Link> : ''}
			</div>
		</div>
	);
};

export default ResetPassword;
