import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/login';
import ProtectedRoute from './routing/ProtectedRoute';
import SearchMedia from './pages/searchMedia';
import AddMedia from './pages/addMedia';
import Register from './pages/register';
import HeaderAuth from './components/HeaderAuth';
import Footer from './components/Footer';
import ListFilter from './pages/listFilter';
import MediaDetails from './pages/MediaDetails';
import HomePage from './pages/homePage';
import ResetPassword from './pages/resetPassword';
// import du style
// import './styles/main.css';
import 'react-loading-skeleton/dist/skeleton.css';
import FriendsListPage from './pages/friendsListPage';
import ForgottenPwd from './pages/forgottenPwd';

const root = () => {
	return (
		<BrowserRouter>
			<HeaderAuth />
			<Routes>
				<Route exact path="/" element={<Login />} />
				<Route path="/login" element={<Login />} />
				{/* <Route path="/CarouTest" element={<CarouTest />} /> */}
				<Route path="/resetPassword/:id/:token" element={<ResetPassword />} />
				<Route path="/register" element={<Register />} />
				<Route path="/forgottenPassword" element={<ForgottenPwd />} />

				{/* ajout d'un middleware, si userinfo n'existe pas, alors redirige vers login */}
				<Route element={<ProtectedRoute />}>
					{/* <Route element={<ProtectedRoute />}> */}
					<Route path="/friendsListPage" element={<FriendsListPage />} />
					<Route path="/homePage" element={<HomePage />} />
					<Route path="/searchMedia" element={<SearchMedia />} />
					<Route path="/addMedia" element={<AddMedia />} />
					<Route path="/listFilter" element={<ListFilter />} />
					<Route path="/MediaDetails" element={<MediaDetails />} />
				</Route>
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default root;
