import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../features/user/userSlice'
import mediasReducer from '../features/medias/mediasSlice'
import friendsReducer from '../features/friends/friendsSlice'


// config du store utilisant un middleware redux-thunk

const store = configureStore({
    reducer: {
        user: userReducer,
        medias: mediasReducer,
        friends: friendsReducer
    },

})


export default store;
