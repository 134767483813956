import React from 'react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';

const MediaDetails = () => {


  const { state } = useLocation();
  let media

  const form = state.formDetails;
  if (state.mediaDetails === undefined) {
    media = state;
  } else {
    media = state.mediaDetails
  }
  // console.log("hype" + form.hype);

  // affiche la liste des genres depuis le tableau de genre de l'api
  const tabGenres = media.genres.map((genre) => {
    return (
      genre.name + " "
    )
  })

  // met au pluriels si il y a plusieurs saisons dans la série
  const convertSeasonTV = () => {
    if (media.number_of_seasons === 1) {
      return (
        media.number_of_seasons + " saison"
      )
    } else {
      return (
        media.number_of_seasons + " saisons"
      )
    }
  }

  // fonction qui convertie le tps en minutes vers des heures avec minutes
  const convertTimeMovie = () => {
    let heure = Math.trunc(media.runtime / 60);
    let min = media.runtime % 60;
    if (heure > 1 && min > 1) {
      return (
        <div>
          <p>{heure} heures et {min} minutes</p>
        </div>
      )
    } else if (heure > 1) {
      return (
        <div>
          <p>{heure} heures et {min} minute</p>
        </div>
      )
    } else if (min > 1) {
      return (
        <div>
          <p>{heure} heure et {min} minutes</p>
        </div>
      )
    } else {
      return (
        <div>
          <p>{heure} heure et {min} minute</p>
        </div>
      )
    }
  }

  // fait la moyenne de la durée des épisodes grace au tableau de l'api qui nous fournis la durée min et max des episodes
  const convertTimeTV = () => {
    const tabTimeTV = media.episode_run_time;
    // previousValue et currentValue sont 2 variables utilisisées juste apres pour definir quel calcul sera effectué sur le tableau : ici, une addition entre toute les cases du tableau
    const timeTV = (tabTimeTV.reduce((previousValue, currentValue) => previousValue + currentValue, 0)) / tabTimeTV.length;
    return (
      timeTV
    )
  }



  let fire = [];

  if (form) {
    for (let i = 0; i < form.hype; i++) {
      let fireItem = <FontAwesomeIcon className='fontAwesomeIcon' icon={faFire} />
      fire.push(fireItem);
    }
  }

  return (
    <div className='mediaDetails marginButton'>
      <div id='cover'>
        <div className='filterImg'></div>
        <img id='fond' src={`https://image.tmdb.org/t/p/original${media.backdrop_path}`} alt={media.title} />
        <img id='poster' src={`https://image.tmdb.org/t/p/original${media.poster_path}`} alt={media.title} />
        <div className='details'>
          <h1>{media?.type === "tv" ? media.name : media.title}</h1>
          <div>
            <p>{media?.type === "tv" ? media.first_air_date : media.release_date}</p>
            {media?.type === "tv" && media.number_of_episodes ? <p>{convertSeasonTV()} / {media.number_of_episodes} épisodes<br />{media.episode_run_time[0]} minutes par épisode</p> : <p>{convertTimeMovie()}</p>}
            {/* {media.type === "tv" ? <p>{convertSeasonTV()} / {media.number_of_episodes} épisodes<br />{convertTimeTV()} minutes par épisodes</p> : <p>{convertTimeMovie()}</p>} */}
            <div className='genreList'>
              <p>{tabGenres}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='desc'>
        <p>{media.overview}</p>
      </div>
      <div id="hyppeIcons">
        {fire}
      </div>
    </div>

  )
}

export default MediaDetails