import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { forgottenPwd, getAllUsers, getUserDetails, register } from '../features/user/userActions';

const ForgottenPwd = () => {
	const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	const { emailMsg } = useSelector((state) => state.user);

	const mailRef = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [ email, setEmail ] = useState('');
	const [ validEmail, setValidEmail ] = useState(false);
	const [ emailFocus, setEmailFocus ] = useState(true);
	const [ errMsg, setErrMsg ] = useState('');

	useEffect(
		() => {
			setValidEmail(EMAIL_REGEX.test(email));
		},
		//Dès que l'email change on refait l'opération du dessus, on retest sa validité
		[ email ]
	);

	useEffect(() => {
		mailRef.current.focus();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		// if button enabled with JS hack

		const v2 = EMAIL_REGEX.test(email);

		if (!v2) {
			setErrMsg('Information Invalides');
			return;
		} else {
			const data = {
				email: email
			};
			dispatch(forgottenPwd(data));
		}
	};

	return (
		<div className="forgottenContainer">
			<div className="flexFormContainer">
				<h1>Veuillez entrer votre Email</h1>
				<form onSubmit={handleSubmit}>
					<label htmlFor="email">
						Email
						<FontAwesomeIcon icon={faCheck} className={validEmail ? 'valid' : 'hide'} />
						<FontAwesomeIcon icon={faTimes} className={validEmail || !email ? 'hide' : 'invalid'} />
					</label>

					<input
						type="text"
						id="email"
						ref={mailRef}
						autoComplete="on"
						onChange={(e) => setEmail(e.target.value)}
						value={email}
						required
						aria-invalid={validEmail ? 'false' : 'true'}
						aria-describedby="uidnote"
						onFocus={() => setEmailFocus(true)}
						onBlur={() => setEmailFocus(false)}
					/>
					<button disabled={!validEmail ? true : false}>Enregistrer</button>
				</form>

				{/* {success && ? <p>Un Email à bien été envoyé à cet adresse</p> : ''} */}

				<p> {emailMsg}</p>
			</div>
		</div>
	);
};

export default ForgottenPwd;
