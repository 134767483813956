import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMedia, getUserDetails } from "../features/user/userActions";
import axios from 'axios'
import ModalAddFriend from '../components/ModalAddFriend';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styled } from '@mui/material/styles';
// import Select from 'react-select';




const AddMedia = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { state } = useLocation();
    const [mediaFullResult, setMediaFullResult] = useState({});
    const listFriends = useSelector((state) => state.friends.friends);
    const { successAddMedia } = useSelector(state => state.user);
    const [listFriendsLocal, setListFriendsLocal] = useState();
    let optionsFriends = [];

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#CB8E0B',
            fontSize: '100px',
            margin: '0 10px'

        },
        '& .MuiRating-iconHover': {
            color: '#CB8E0B',
            fontSize: '100px',
            margin: '0 10px'


        },
        '& .MuiRating-iconEmpty': {
            color: '#b7b2b2',
            fontSize: '100px',
            margin: '0 10px'

        },
    });



    // const [optionsFriends, setOptionsFriends] = useState([])


    useEffect(() => {
        setListFriendsLocal(listFriends)
    }, [listFriends])


    if (listFriendsLocal) {
        optionsFriends = listFriendsLocal.map((friend) => {
            return (
                <option value={friend.id}>{friend.pseudo}</option>
            )
        })
    }

    // recuperation de l'id du media dans state
    const mediaId = state.media.id
    const searchType = state.searchType

    //fonction pour faire appel api pour récupérer les infos de d'un film complet
    const mediaFull = async (mediaId, searchType) => {
        const restult = await axios(`https://api.themoviedb.org/3/${searchType}/${mediaId}?api_key=b36fe485cc1c10abeab5de5fcde6a4d2&language=fr-FR`)
        setMediaFullResult(restult.data)
    }


    // recupération des données du formulaire avec useForm
    const { register, handleSubmit } = useForm();


    useEffect(() => {
        mediaFull(mediaId, searchType)

    }, [])

    useEffect(() => {
        console.log("form submit : " + register.values);
        if (successAddMedia) {
            dispatch(getUserDetails())

            navigate('/homePage');
        }
    }, [successAddMedia])



    // transformer listfriendLocal en tableau d'objet 
    const optionsFriendsSelect = listFriendsLocal?.map((friend) => {
        return (
            { value: friend._id, label: friend.pseudo }
        )
    })


    // fonction qui récupère les données du formulaire
    const onSubmit = (data, event) => {
        // ajouter une clé hype dans l'objet data
        console.log(data)
        event.preventDefault()
        data.hype = hype
        const datas = {
            form: data,
            media: mediaFullResult,
            mediaType: searchType
        }
        dispatch(addMedia(datas))
    }
    const [hype, setHype] = React.useState();



    return (
        <div className='formAddMedia marginButton'>
            <form className='flexFormContainer'
                onSubmit={handleSubmit(onSubmit)} >
                <div className="form-group">
                    <label htmlFor="hype" className='labelHype'>Hype</label>
                    <Box
                        sx={{
                            '& > legend': { mt: 2 },
                        }}
                    >
                        <StyledRating
                            name="hype"
                            size="large"
                            defaultValue='1'
                            value={hype}
                            max={3}
                            onChange={(event, newValue) => {
                                setHype(newValue)

                            }}
                            icon={<FontAwesomeIcon icon={faFire} />}
                            emptyIcon={<FontAwesomeIcon icon={faFire} />}
                        />
                    </Box>

                </div>
                <div className="form-group" id="formBtnFriend">
                    <label htmlFor="selectFriend">Choisi un ami avec qui regarder !</label>

                    <select name="selectFriend" id="selectFriend"
                        {...register("friends")}>

                        <option value="solo">Je regarde seul !</option>
                        {optionsFriends}
                    </select>

                    <ModalAddFriend
                        textBtnOpenModal="Ton ami n'est pas dans la liste ? Click pour l'ajouter !"
                        textBtnCloseModal="Fermer Modal"
                        textTitleModal="Titre Modal"
                        textContentModal="Contenu Modal"
                        textBtnSubmit="Envoyer"
                        // passer le state et le setState de listFriendsLocal
                        listFriendsLocal={listFriendsLocal}
                        setListFriendsLocal={setListFriendsLocal}
                    />

                </div>

                <div className="form-group">
                    <input type="text" name="par" id="par" placeholder="Conseillé par ?" {...register("par")} />
                </div>
                <div className="form-group">
                    <select name="plateform" id="plateform" {...register("plateform")}  >
                        <option value="0">Sur quelle plateforme ?</option>
                        <option value="1">NetFlix</option>
                        <option value="2">Prime Video</option>
                        <option value="3">Disney +</option>
                        <option value="3">Autre</option>
                    </select>

                </div>
                <button type="submit"
                    className='btnStyle'
                // onClick={(event) => onSubmit(event)}
                >Envoyer</button>

            </form>

        </div>
    )
}

export default AddMedia