import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useState } from 'react';

import { useSelector } from "react-redux";

const SearchMedia = () => {
	const navigate = useNavigate();

	const [searchInput, setSearchInput] = useState('');
	const [searchResult, setSearchResult] = useState([]);
	const [searchType, setSearchType] = useState('movie');
	const [media, setMedia] = useState();

	const userMedia = useSelector((state) => state.user.userInfo.media);


	useEffect(() => {
		if (searchInput.length > 2) {
			axios.get(
				`https://api.themoviedb.org/3/search/${searchType}?api_key=b36fe485cc1c10abeab5de5fcde6a4d2&language=fr-FR&query=${searchInput}&page=1&include_adult=false`
			).then((res) => {
				setSearchResult(res.data.results);
			});
		} else if (searchInput.length === 0) {
			setSearchResult([]);
		}
	}, [searchInput, searchType]);

	const selectedMedia = (media) => {
		setMedia(media);
		// redirection vers addMedia avec le media en paramètre
		navigate('/addMedia', { state: { media, searchType } });
	};




	return (
		<div className="containerFlex marginButton">

			<div className="searchMedia">
				{
					userMedia.length > 0 ? <h1>Rechercher un film ou une serie à ajouter à ma liste</h1> : <h1>Ajouter ton premier média et commence à suivre tes amis</h1>
				}

				<form className="formSearchMedia">

					<select
						name="searchType"
						id="searchType"
						className="selectType"
						value={searchType}
						color="secondary"
						autoFocus
						onChange={
							(e) => {
								setSearchType(e.target.value)
							}
						}>
						<option value="movie">Film</option>
						<option value="tv">Série</option>
					</select>
					<input type="text" onChange={(e) => setSearchInput(e.target.value)} placeholder={searchType === 'movie' ? "Saisi le titre du film" : "Saisi le titre de la série"} />
					{/* <TextField id="outlined-basic" label="Rechercher" variant="outlined" className="inputSearch" onChange={(e) => setSearchInput(e.target.value)}
                    size="medium" color="success" /> */}
				</form>
				<div className="resultSearch">

					{searchResult.map((media) => (
						<div className="movieCard" onClick={
							() => {
								selectedMedia(media)
							}
						}>
							{media.poster_path ? <img src={`https://image.tmdb.org/t/p/w500${media.poster_path}`} alt="" /> : ""}

						</div>
					))}

				</div>
			</div>
		</div>

	)
}


export default SearchMedia
