import React from 'react';
import { useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserDetails } from '../features/user/userActions';
import { getMedias } from '../features/medias/mediasAction';
import RandomMedia from '../components/RandomMedia';
import Carousel from '../components/Carousel';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { successAddMediaReset } from '../features/user/userSlice';

function HomePage() {
	const home = useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userInfo } = useSelector((state) => state.user);
	const { medias } = useSelector((state) => state.medias);
	const { suggestions } = useSelector((state) => state.medias);
	const loading = useSelector((state) => state.medias.loading);
	const [MediasLocal, setMediasLocal] = useState([]);
	const [films, setFilm] = useState([]);
	const [series, setSeries] = useState([]);
	const [suggestionsLocal, setSuggestionsLocal] = useState([]);


	useLayoutEffect(() => {
		setMediasLocal(suggestions);
		dispatch(successAddMediaReset())
		dispatch(getUserDetails()).then(res => {
			if (res.payload.media.length === 0) {
				navigate('/searchMedia');
			}
			dispatch(getMedias()).then(res => {
				setMediasLocal(res.payload);
				setSuggestionsLocal(res.payload.suggestions);
				setFilm(res.payload.media.filter((media) => media.mediaDetails.type === 'movie'));
				setSeries(res.payload.media.filter((media) => media.mediaDetails.type === 'tv'));
			})
		})
	}, []);


	useLayoutEffect(
		() => {
			setFilm(medias.filter((media) => media.mediaDetails.type === 'movie'));
			setSeries(medias.filter((media) => media.mediaDetails.type === 'tv'));
			setSuggestionsLocal(suggestions);
		},
		[medias, userInfo.suggestions, suggestions]
	);


	return (
		<div className="marginButton container">
			<div className='homepage'
				ref={home}	>
				<div className="btnLinkAdd">
					<Link to={"/searchMedia"}>
						Ajouter un média
					</Link>

				</div>
				{loading === true ? (
					<Skeleton
						count={20}
						// height="100%"
						width="50%"
						margin="auto"
						alignItems="center"
						baseColor="#181818"
						highlightColor="#F1F1F1"
						duration={1.9}
					/>
				) : (
					< RandomMedia medias={medias} />
				)}

				<div className="carouFilms containerCarou">
					<h1>Mes films </h1>
					{loading === true ? (
						<Skeleton
							count={20}
							height="100%"
							baseColor="#181818"
							highlightColor="#F1F1F1"
							duration={1.9}
							borderRadius="20px"
						/>
					) : films.length >= 1 ? (
						<Carousel item={films} />
					) : (
						'Vous n\'avez pas encore ajouté de films'
					)}
				</div>
				<div className="carouSerie containerCarou">
					<h1>Mes series</h1>
					{loading === true ? (
						<Skeleton
							count={20}
							height="100%"
							baseColor="#181818"
							highlightColor="#F1F1F1"
							duration={1.9}
							borderRadius="20px"
						/>
					) : series.length >= 1 ? (
						<Carousel item={series} />
					) : (
						'Vous n\'avez pas encore ajouté de series'
					)}
				</div>
				<div className="carouSerie containerCarou">
					<h1>Suggestions de mes amis</h1>
					{loading === true ? (
						<Skeleton
							count={20}
							height="100%"
							baseColor="#181818"
							highlightColor="#F1F1F1"
							duration={1.9}
							borderRadius="20px"
						/>
					) : suggestionsLocal?.length >= 1 ? (
						<Carousel item={suggestionsLocal} />
					) : (
						'Pas de suggestions'
					)}
				</div>
			</div>
		</div>
	);
}

export default HomePage;
