import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Media from '../components/Media';
import { getMedias } from '../features/medias/mediasAction';
import FilteredMediaDisplay from '../components/FilteredMediaDisplay';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion, AnimatePresence } from 'framer-motion';

const ListFilter = () => {
	const dispatch = useDispatch();
	const { userInfo } = useSelector((state) => state.user);
	const { medias } = useSelector((state) => state.medias);

	const [filterType, setFilterType] = useState('allTypes');

	const [selectedHype, setSelectedHype] = useState({
		hype1: 0,
		hype2: 0,
		hype3: 0
	});

	let mediaDisplay = [...medias];
	let animationGo = false;
	let mappingMedias;

	const checkingHypeLvl = (stateHype) => {
		const hypeTab = Object.values(stateHype);

		{
			mediaDisplay = mediaDisplay.filter((media) => hypeTab.includes(media.formDetails.hype));
		}

		return mediaDisplay;
	};

	// useEffect(() => {
	// 	dispatch(getMedias());
	// }, []);

	if (filterType != 'allTypes') {
		mediaDisplay = mediaDisplay.filter((media) => media.mediaDetails.type === filterType);
	}

	if (selectedHype.hype1 !== 0 || selectedHype.hype2 !== 0 || selectedHype.hype3 !== 0) {
		animationGo = true;
		checkingHypeLvl(selectedHype);
	}

	if (mediaDisplay.length >= 1) {
		mappingMedias = mediaDisplay.map((media) => {
			return <FilteredMediaDisplay media={media} key={media.mediaDetails.id} />;
		});
	}

	return (
		<div className="filterPageContainer">
			<div className="filterContainer">
				<select
					name="filterType"
					id="filterType"
					className="filterSelect"
					color="secondary"
					value={filterType}
					autoFocus
					onChange={(e) => {
						setFilterType(e.target.value);
					}}
				>
					<option value="allTypes">Tout voir</option>
					<option value="movie">Film</option>
					<option value="tv">Série</option>
				</select>
				<div className="hypeRadioSection curseur">
					<div className="listFormGroup">
						<input
							type="checkbox"
							name="hypeRadio"
							id="selectedHype1"
							value="1"
							className="check-with-label"
							onClick={(e) => {
								setSelectedHype({ ...selectedHype, hype1: Number(e.target.value) });
							}}
							onChange={(e) => {
								if (selectedHype.hype1 === Number(e.target.value)) {
									setSelectedHype({
										...selectedHype,
										hype1: selectedHype.hype1 - selectedHype.hype1
									});
								}
							}}
						/>

						<label className='curseur' htmlFor="selectedHype1">
							<FontAwesomeIcon icon={faFire} />
						</label>
					</div>
					<div className="listFormGroup">
						<input
							type="checkbox"
							name="hypeRadio"
							id="selectedHype = 2"
							value="2"
							className="check-with-label"
							onClick={(e) => {
								setSelectedHype({ ...selectedHype, hype2: Number(e.target.value) });
							}}
							onChange={(e) => {
								if (selectedHype.hype2 === Number(e.target.value)) {
									setSelectedHype({
										...selectedHype,
										hype2: selectedHype.hype2 - Number(e.target.value)
									});
								}
							}}
						/>

						<label className='curseur' htmlFor="selectedHype2">
							<FontAwesomeIcon icon={faFire} />
							<FontAwesomeIcon icon={faFire} />
						</label>
					</div>
					<div className="listFormGroup">
						<input
							type="checkbox"
							name="hypeRadio"
							id="selectedHype = 3"
							value="3"
							className="check-with-label"
							onClick={(e) => {
								setSelectedHype({ ...selectedHype, hype3: Number(e.target.value) });
							}}
							onChange={(e) => {
								if (selectedHype.hype3 === Number(e.target.value)) {
									setSelectedHype({
										...selectedHype,
										hype3: selectedHype.hype3 - Number(e.target.value)
									});
								}
							}}
						/>

						<label className='curseur' htmlFor="selectedHype3">
							<FontAwesomeIcon icon={faFire} />
							<FontAwesomeIcon icon={faFire} />
							<FontAwesomeIcon icon={faFire} />
						</label>
					</div>
				</div>
			</div>
			{animationGo ? (
				<motion.div layout className="mediasFlexContainer">
					<AnimatePresence>{mappingMedias}</AnimatePresence>
				</motion.div>
			) : (
				<div layout className="mediasFlexContainer">
					{mappingMedias}
				</div>
			)}
		</div>
	);
};

export default ListFilter;
