import { createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { getMedias } from '../medias/mediasAction';

import {
	getAllUsers,
	userLogin,
	getUserDetails,
	register,
	deleteUser,
	addMedia,
	forgottenPwd,
	resetPwd
} from './userActions';

const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : '';

const initialState = {
	loading: false,
	userInfo: false,
	userToken,
	userMsg: '',
	error: null,
	success: false,
	emailMsg: '',
	successAddMedia: false
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		logout: (state) => {
			localStorage.removeItem('userToken'); // supprime le token du localStorage
			state.loading = false;
			state.userInfo = false;
			state.userToken = null;
			state.error = null;
			state.emailError = '';
			state.userMsg = '';
		},
		successAddMediaReset: (state) => {
			state.successAddMedia = false;
		}
	},
	extraReducers: {
		// Dans extraReducers se trouve nos reducers asynchrones

		// test appel API via thunk/redux
		[getAllUsers.pending]: (state) => {
			state.loading = true;
		},

		[getAllUsers.fulfilled]: (state, action) => {
			state.user = action.payload;
		},
		[getAllUsers.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		// register

		[register.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},

		[register.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.success = true;
			state.userMsg = payload.data;
		},
		[register.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		/// login utilisateur
		[userLogin.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},
		[userLogin.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.error = !payload;
			state.userInfo = payload;
			state.userToken = payload.token;
		},
		[userLogin.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		[addMedia.pending]: (state) => {
			state.successAddMedia = false;
		},
		[addMedia.fulfilled]: (state) => {
			state.successAddMedia = true;
		},

		// recupération des infos utilisateur
		[getUserDetails.pending]: (state) => {
			state.loading = true;
		},
		[getUserDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.userInfo = payload;
		},
		[getUserDetails.rejected]: (state, { payload }) => {
			state.loading = false;
		},

		[deleteUser.fulfilled]: (state, { payload }) => {
			localStorage.removeItem('userToken'); // supprime le token du localStorage
			state.loading = false;
			state.userInfo = false;
			state.userToken = null;
			state.error = null;
		},

		// resetPwd

		[resetPwd.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},

		[resetPwd.fulfilled]: (state) => {
			state.loading = false;
			state.success = true;
		},
		[resetPwd.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		},

		//forgottenPwd
		[forgottenPwd.pending]: (state) => {
			state.loading = true;
			state.error = null;
		},

		[forgottenPwd.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.success = true;
			state.emailMsg = payload.data;
		},
		[forgottenPwd.rejected]: (state, { payload }) => {
			state.loading = false;
			state.error = payload;
		}
	}
});

export default userSlice.reducer;
export const { logout, successAddMediaReset } = userSlice.actions;
