import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../styles/modalDelete.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { deleteMedia, getMedias } from "../features/medias/mediasAction";
import { getUserDetails } from '../features/user/userActions';

import { useRef } from "react";


const ModalDelete = ({ id, action }) => {

  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const toggleModal = () => {
    setShowModal(!showModal);
  };



  return (
    <>
      <button onClick={toggleModal} className="btn-modal-delete">
        <FontAwesomeIcon icon={faCircleXmark} />
      </button>
      <div className="modalFullscrenne"></div>
      {/* lui donner une condition ! modal "true" alors apparition du madal */}
      {showModal && (
        <div className="">
          <div className="overlay">
            <div className="modalDelete">
              <div className="modal-content">

                <button
                  className="btnModaleDelete"
                  id="btnModaleDelete"
                  onClick={() => {
                    dispatch(deleteMedia(id))
                      .then(() => {
                        dispatch(getUserDetails()).then(() => {
                          dispatch(getMedias());
                        });
                      })
                  }}

                > Confirmer la suppression</button>

                <button onClick={toggleModal} className="close-modal-delete">
                  <FontAwesomeIcon icon={faCircleXmark} />

                </button>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
}

export default ModalDelete;
