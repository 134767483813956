import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

const API_URL = process.env.REACT_APP_API_URL;

export const getMedias = createAsyncThunk('medias/getMedias', async (arg, { getState }) => {
	const { user } = getState();


	try {
		const tab = user.userInfo.media;
		const idSuggest = user.userInfo.suggestions
		const { data } = await axios.post(`${API_URL}getMedia`, { tab: tab, idSuggest: idSuggest, id: user.userInfo._id });
		return data;
	} catch (error) {
		console.log(error);
	}
});

export const getMediasFriend = createAsyncThunk('medias/getMediasFriend', async (userID) => {
	try {
		const { data } = await axios.post(`${API_URL}getMediasFriend`, { userID });
		return data;
	} catch (error) {
		console.log(error);
	}
});


export const deleteMedia = createAsyncThunk('medias/deleteMedia', async (id, { getState }) => {
	const { user } = getState();
	await axios.post(`${API_URL}deleteMedia`, { id: id, userID: user.userInfo._id }).then((res) => {
		console.log(res);
	});
});
