import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails } from '../features/user/userActions'
import { Link } from 'react-router-dom';
import Logo from '../img/logo/Logo-Hyppy.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClapperboard } from '@fortawesome/free-solid-svg-icons';
import MenuDesktop from './MenuDesktop'
import { getFriends } from '../features/friends/friendsAction';
import { useNavigate } from 'react-router-dom';
import ModalProfile from './ModalProfil';



const HeaderAuth = () => {

    const [removeEventListener, setRemoveEventListener] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef();

    const { userInfo, userToken } = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (userToken) {
            console.log('userToken', userToken);
            console.log('token ok');
            dispatch(getUserDetails(
                userToken
            )).then(res => {
                console.log('res', res);
                dispatch(getFriends(userToken))
            })
        }
    }, [userToken])




    let useClickOutside = (handler) => {
        let domNode = useRef();

        useEffect(() => {
            let maybeHandler = (event) => {
                if (!domNode.current?.contains(event.target)) {
                    handler();
                }
            };

            document.addEventListener("mousedown", maybeHandler);

            return () => {
                document.removeEventListener("mousedown", maybeHandler);
            };
        });

        return domNode;
    };


    let domNode = useClickOutside(() => {
        setShowModal(false);
    });


    return (

        <div className='header' id={userInfo ? "" : "center"} >
            {userInfo ?
                <div className='headerGroupIcone' id="clap">
                    <FontAwesomeIcon icon={faClapperboard} className='iconeBurger' />
                    <span>Menu</span>
                    <MenuDesktop />

                </div> : null}
            <Link to={"/homePage"}>
                <div className='title'>
                    {/* <div> */}
                    {/* exemple d'import d'image depuis le dossier src */}
                    {/* <img src={Logo} alt="Logo de Hyppy List représentant un sac de pop-corn dans une flamme" /> */}
                    {/* </div> */}
                    <div
                        // naviger vers la home page en cliquant sur le logo
                        onClick={() => navigate('/homePage')}
                    >
                        <h1>Hyppy List</h1>
                    </div>
                </div>
            </Link>
            <div className='headerGroupIcone'>
                {userInfo ?
                    <ModalProfile
                        user={userInfo}
                        setRemoveEventListener={setRemoveEventListener}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        modalRef={domNode}
                    /> : null}


                <span className='userName'>{userInfo ? userInfo.pseudo : ""}</span>
            </div>
        </div >
    )
}

export default HeaderAuth

