import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userLogin } from "../features/user/userActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';


function Login() {

	const { loading, userInfo, error } = useSelector(state => state.user);
	console.log(error);
	const [showPwd, setShowPwd] = useState(false);
	const dispatch = useDispatch();
	const { register, handleSubmit } = useForm();
	const navigate = useNavigate();

	useEffect(() => {
		if (userInfo !== false) {

			console.log("user connecté");
			navigate('/homePage');
		}


	}, [navigate, userInfo])

	const submitForm = (data) => {
		dispatch(userLogin(data));
	}

	return (
		<div className="flexFormContainer marginButton">

			{error ? <p>{error}</p> : null}

			<div className="headLoginScreen">
				<h1>Connectez-vous !</h1>
				{/* <img src={`${process.env.PUBLIC_URL}/img/picture.png`} alt="" /> */}
				<span>
					<FontAwesomeIcon icon={faUser} />
				</span>


			</div>

			<form onSubmit={handleSubmit(submitForm)}>

				<div className="form-group">
					{/* <label htmlFor="email">Email</label> */}
					<input type="email" name="email" id="email" placeholder="Email" {...register('email')} />

				</div>
				<div className="form-group">
					{/* <label htmlFor="password">Mot de passe</label>  */}
					<input type={showPwd ? "text" : "password"} name="password" id="password" placeholder="Mot de passe" {...register('password')} />

					{/* <i class="far-eye-slash" id="togglePassword" onClick={()=>setShowPwd(!showPwd)}></i>
                    */}
					<FontAwesomeIcon icon={showPwd ? faEye : faEyeSlash} onClick={() => setShowPwd(!showPwd)} id="togglePassword" />



				</div>

				<h1 className="redirect" onClick={() => navigate('/forgottenPassword')}>Vous avez oubliez votre mot de passe ?</h1>

				<span style={
					{ display: "block", textAlign: "center", marginTop: "1rem", color: "red" }

				}>{!error ? "" : "Identifiant invalide"}</span>
				<button type="submit"
					className="btnStyle"
					disabled={loading}>Connexion</button>
				<Link to={'/register'}>
					<div>
						<h1 className="redirect" >Vous n'avez pas encore de compte ? <br />Créez-en un dès maintenant !</h1>
					</div>
				</Link>
			</form>


		</div >

	);
}

export default Login;
