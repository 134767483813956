
import ModalDelete from './ModalDelete';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const CarouselItem = ({ item, action }) => {

    const navigate = useNavigate();

    const [media, setMedia] = useState()

    const selectMediaDetails = (media) => {
        setMedia(media)
        navigate('/MediaDetails', { state: media })
    }

    return (
        <div className={`itemCarousel`}>
            {item.mediaDetails ? <>
                <img src={`https://image.tmdb.org/t/p/original${item.mediaDetails.poster_path}`} alt={item.mediaDetails.title} onClick={() => {
                    navigate("/MediaDetails", { state: item })
                }}
                />
                <div >
                    <ModalDelete
                        id={item.mediaDetails._id}
                    // action={action}
                    />
                </div></> : <>
                <img src={`https://image.tmdb.org/t/p/original${item.poster_path}`} alt={item.title} onClick={() => {
                    navigate("/MediaDetails", { state: item })
                }}
                />
                <div >
                    <ModalDelete
                        id={item._id}
                    // action={action}
                    />
                </div></>}
        </div>
    );
};

export default CarouselItem;
